.FooterBottom {
  width: 90%;
  margin: 0 auto;
  /* max-width: 766px; */
  padding: 34px 0px;
  display: flex;
  flex-direction: row-reverse
}

.FooterBottom .copyright {
  margin-right: auto;
}

@media (max-width: 576px) {
  .FooterBottom {
    width: 90%;
    display: block;
    text-align: center;
    padding-top: 0px;
    font-size: 12px;
  }

  .FooterBottom .reg-lang-selector {
    padding: 18px 0px;
  }

  .FooterBottom .copyright {
    margin-right: 0;
    border-top: 2px solid #E8E8E8;
    padding: 18px 0px;
  }
}

@media (max-width: 374px) {
  .FooterBottom {
    font-size: 10px;
  }
}
