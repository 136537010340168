main {
  margin-top: 100px;
  margin-bottom: 45px;
}

@media (max-width: 576px) {
  main {
    margin-top: 80px;
  }
}

@media (max-width: 376px) {
  main {
    margin-bottom: 35px;
  }
}
