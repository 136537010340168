.ForgotPassword .backButton {
  position: relative;
  width: 85%;
  margin: 0 auto;
  max-width: 766px;
}

.ForgotPassword .body {
  width: 300px;
  margin: 0 auto;
}

.ForgotPassword .title {
  font-size: 20px;
  font-weight: 700;
  color: #2F3D4D;
  text-align: center;
  margin-bottom: 10px;
  padding: 0px 5px;
}

.ForgotPassword .subtitle {
  font-size: 14px;
  text-align: center;
  margin-bottom: 25px;
}

.ForgotPassword .errors {
  font-size: 12px;
  font-weight: 700;
  color: #E16C6A;
  position: relative;
  margin-bottom: 15px;
}

.ForgotPassword .errors .error-icon {
  width: 14px;
  position: absolute;
  top: 0;
  left: 5px;
}

.ForgotPassword .errors .error-text {
  display: block;
  margin-left: 25px;
}

.ForgotPassword .label {
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 8px;
}

.ForgotPassword .form-field {
  width: 100%;
  font-size: 17px;
  margin-bottom: 15px;
  padding: 8px 0px 8px 5px;
  border: none;
  border-bottom: 1px solid #CBCBCB;
  border-radius: unset;
}

.ForgotPassword .form-field::placeholder {
  font-size: 17px;
  color: #CECECE;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  margin-top: 2px;
}

.ForgotPassword .form-field:not([value=""]) {
  color: #364553;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  border-bottom: 1px solid #CBCBCB;
  padding: 8px 5px;
  width: 98.5%;
}

.ForgotPassword .form-field-text:not([value=""]),
.ForgotPassword .form-field-password:not([value=""]) {
  font-size: 17px;
}

.ForgotPassword .form-field:focus {
  outline: none;
}

.ForgotPassword .has-error {
  background-color: #FDF3F4;
  border-bottom: 1px solid #D96161 !important;
  color: #D96161 !important;
}

.ForgotPassword .has-error::placeholder {
  color: #D96161;
}

@media (max-width: 576px) {
  .ForgotPassword .backButton {
    width: 90%;
  }
}

@media (max-width: 376px) {
  .ForgotPassword .body {
    width: 275px;
  }
}
