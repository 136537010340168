.ResetPassword .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 1002;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.ResetPassword .overlay .logo {
  text-align: center;
}

.ResetPassword .overlay .logo img {
  height: 30px;
}

.ResetPassword .overlay .error {
  margin-top: 0px;
}

.ResetPassword .overlay .loadingInfo {
  margin-top: 10px;
}

.ResetPassword .overlay .text {
  font-size: 14px;
  max-width: 275px;
}

.ResetPassword .body {
  width: 300px;
  margin: 0 auto;
}

.ResetPassword .title {
  font-size: 20px;
  font-weight: 700;
  color: #2F3D4D;
  text-align: center;
  margin-bottom: 10px;
  padding: 0px 5px;
}

.ResetPassword .subtitle {
  font-size: 14px;
  text-align: center;
  margin-bottom: 25px;
}

.ResetPassword .errors {
  font-size: 12px;
  font-weight: 700;
  color: #E16C6A;
  position: relative;
  margin-bottom: 15px;
}

.ResetPassword .errors .error-icon {
  width: 14px;
  position: absolute;
  top: 0;
  left: 5px;
}

.ResetPassword .errors .error-text {
  display: block;
  margin-left: 25px;
}

.ResetPassword .label {
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 8px;
}

.ResetPassword .form-field {
  width: 100%;
  font-size: 17px;
  padding: 8px 0px 8px 5px;
  border: none;
  border-bottom: 1px solid #CBCBCB;
  border-radius: unset;
}

.ResetPassword .form-field::placeholder {
  font-size: 17px;
  color: #CECECE;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  margin-top: 2px;
}

.ResetPassword .form-field:not([value=""]) {
  color: #364553;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  border-bottom: 1px solid #CBCBCB;
  padding: 8px 5px;
  width: 98.5%;
}

.ResetPassword .form-field-text:not([value=""]),
.ResetPassword .form-field-password:not([value=""]) {
  font-size: 17px;
  width: 98.25%;
}

.ResetPassword .form-field:focus {
  outline: none;
}

.ResetPassword #password label {
  position: relative;
}

.ResetPassword #password label .showHidePassword {
  text-align: center;
}

.ResetPassword #password label .showHidePasswordText {
  display: block;
  font-size: 10px;
  text-align: center;
  font-weight: 200;
}

.ResetPassword .showHidePassword {
  color: #747D9F;
  position: absolute;
  right: 0;
  bottom: -30px;
}

.ResetPassword .showHidePassword:hover {
  cursor: pointer;
}

.ResetPassword .passwordStrength {
  margin-bottom: 25px;
  margin-right: -5px;
  font-size: 12px;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 9px 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 3px 9px 3px rgba(0,0,0,0.1);
  box-shadow: 0px 3px 9px 3px rgba(0,0,0,0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ResetPassword .passwordStrength .titleText {
  font-weight: 700;
}

.ResetPassword .passwordRequirements {
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.ResetPassword .passwordRequirement {
  margin-bottom: 3px;
  color: #7db642;
}

.ResetPassword .passwordRequirement.error {
  color: #e96d6c;
}

.ResetPassword .passwordRequirement svg {
  font-size: 13px;
}

.ResetPassword .passwordRequirement span {
  margin-left: 12px;
  font-weight: 300;
}

.ResetPassword .passwordStrengthMeter {
  margin-top: 15px;
}

.ResetPassword .passwordStrengthMeter .text {
  margin-bottom: 8px;
}

.ResetPassword .passwordStrengthMeter .score {
  font-weight: bold;
  margin-left: 3px;
}

.ResetPassword .passwordStrengthMeter .progressbar-progress {
  border-radius: 10px;
}

@media (max-width: 376px) {
  .ResetPassword .body {
    width: 275px;
  }
}
