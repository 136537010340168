.RegLangSelector {
    position: absolute;
    top: 80px;
    left: 65px;
    color: #CBCBCB;
    font-size: 14px;
    font-weight: 700;
  }
  
  .RegLangSelector .back-button-text {
    margin-left: 5px;
  }

  @media (max-width: 1500px) {
    .top-region-selector .RegLangSelector {
      left: 1200px !important; 
    }
  }

  @media (max-width: 1400px) {
    .top-region-selector .RegLangSelector {
      left: 1100px !important; 
    }
  }

  @media (max-width: 1300px) {
    .top-region-selector .RegLangSelector {
      left: 1000px !important; 
    }
  }


  @media (max-width: 1200px) {
    .top-region-selector .RegLangSelector {
      left: 900px !important; 
    }
  }

  @media (max-width: 1100px) {
    .top-region-selector .RegLangSelector {
      left: 800px !important; 
    }
  }

  @media (max-width: 900px) {
    .top-region-selector .RegLangSelector {
      left: 570px !important; 
    }
  }

  @media (max-width: 700px) {
    .top-region-selector .RegLangSelector {
      left: 500px !important;
    }
  }

  @media (max-width: 600px) {
    .top-region-selector .RegLangSelector {
      left: 400px !important;
    }
  }
  @media (max-width: 500px) {
    .top-region-selector .RegLangSelector {
      left: 300px !important;
    }
  }
  
  @media (max-width: 400px) {
    .top-region-selector .RegLangSelector {
      left: 270px !important;
    }
  }
  @media (max-width: 330px) {
    .top-region-selector .RegLangSelector {
      left: 240px !important;
    }
  }
  