.BackButton {
  position: absolute;
  top: 80px;
  left: 65px;
  color: #CBCBCB;
  font-size: 14px;
  font-weight: 700;
}

.BackButton .back-button-text {
  margin-left: 5px;
}

@media (max-width: 1100px) {
  .BackButton {
    left: 35px;
  }
}

@media (max-width: 400px) {
  .BackButton {
    left: 15px;
  }
}
