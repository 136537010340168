.BannerImage {
  margin-top: 100px;
}

.BannerImage > .banner-image {
  background-image: url('banner-image-resized.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  height: 32vh;
  margin-bottom: 25px;
}

@media (max-width: 1100px) {
  .BannerImage > .banner-image {
    height: 30vh;
  }
}

@media (max-width: 576px) {
  .BannerImage > .banner-image {
    height: 20vh;
  }
}

@media (max-width: 400px) {
  .BannerImage > .banner-image {
    height: 15vh;
  }
}
