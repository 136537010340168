.OpenIdProxy {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OpenIdProxy .logo {
  text-align: center;
}

.OpenIdProxy .logo img {
  height: 30px;
  margin-bottom: 10px;
}
