.OPError {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OPError .logo {
  text-align: center;
}
.OPError .logo img {
  height: 30px;
}

.OPError .logo .errorMessage {
  max-width: 300px;
  font-style: italic;
  margin-top: 10px;
  color: #2F3D4D;
}
