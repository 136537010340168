.CustomerSupport .body {
  width: 500px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.CustomerSupport .title {
  font-size: 20px;
  font-weight: 700;
  color: #2F3D4D;
  text-align: center;
  margin-bottom: 10px;
  padding: 0px 5px;
}

.CustomerSupport .subtitle {
  font-size: 14px;
  text-align: center;
  margin-bottom: 25px;
}

.CustomerSupport .searchForm {
  position: relative;
}

.CustomerSupport input  {
  width: 100%;
  color: #747d9f;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  border: none;
  -webkit-box-shadow: 0px 2px 23px -3px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 23px -3px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 23px -3px rgba(0,0,0,0.2);
  border-radius: 22px;
  font-size: 17px;
  padding: 13px 0px 13px 22px;
}

.CustomerSupport input:focus  {
  outline: none;
}

.CustomerSupport input::placeholder {
  font-size: 17px;
  color: #747d9f;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  padding: 0px 0px 0px 0px;
}

.CustomerSupport .searchForm .search-icon {
  filter: brightness(0) saturate(100%) invert(63%) sepia(11%) saturate(1467%) hue-rotate(242deg) brightness(89%) contrast(85%);
  position: absolute;
  top: 12px;
  right: 19px;
}

.CustomerSupport .searchForm .clear-icon {
  filter: brightness(0) saturate(100%) invert(63%) sepia(11%) saturate(1467%) hue-rotate(242deg) brightness(89%) contrast(85%);
  position: absolute;
  top: 12px;
  right: 19px;
  width: 24px;
}

.CustomerSupport .searchForm .search-icon:hover,
.CustomerSupport .searchForm .clear-icon:hover {
  cursor: pointer;
}

.CustomerSupport .icon-flip-horizontal {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

@media (max-width: 576px) {
  .CustomerSupport .body {
    width: 400px;
  }

  .CustomerSupport .title {
    padding: 25px 5px 5px 5px;
  }
}

@media (max-width: 476px) {
  .CustomerSupport .body {
    width: 300px;
  }
}

@media (max-width: 376px) {
  .CustomerSupport .body {
    width: 275px;
  }
}
