.Header {
  background-color: #ffffff;
  padding: 0 60px;
  min-height: 50px;
  border-top: 5px solid #BC84CA;
  -webkit-box-shadow: 0px -1px 15px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px -1px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -1px 15px 0px rgba(0,0,0,0.1);
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  /* center logo */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.Header > .logo {
  margin-top: 13px;
  width: 105px;
  display: block;
}

.Header > .logo-mobile {
  display: none;
}

@media (max-width: 1100px) {
  .Header {
    padding: 0 30px;
  }
}

@media (max-width: 576px) {
  .Header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .Header > .logo {
    display: none;
  }

  .Header > .logo-mobile {
    display: block;
    width: 20px;
  }
}
