.CustomerSupportList {
  margin-top: 35px;
  text-align: left;
  width: 450px;
  margin: 35px auto 0px auto;
}

.CustomerSupportList .noResults {
  text-align: center;
  font-style: italic;
  font-size: 14px;
}

.CustomerSupportListItem {
  margin-bottom: 8px;
  border-bottom: 2px solid #ecebeb;
}

.CustomerSupportListItem .country {
  font-weight: 700;
}

.CustomerSupportListItem .city {
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .CustomerSupportList {
    width: 400px;
  }
}

@media (max-width: 476px) {
  .CustomerSupportList {
    width: 300px;
  }
}

@media (max-width: 376px) {
  .CustomerSupportList {
    width: 275px;
  }
}
