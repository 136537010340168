.ZoneImage {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  flex-direction: column;
  flex-grow: 1;
  min-height: 135px;
}

.ZoneImage.selected {
  filter: brightness(0) saturate(100%) invert(46%) sepia(85%) saturate(375%) hue-rotate(242deg) brightness(81%) contrast(85%);
  color: #a25eb5;
}

.ZoneImage.selected .name {
  font-weight: 700 !important;
}

.ZoneImage:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(46%) sepia(85%) saturate(375%) hue-rotate(242deg) brightness(81%) contrast(85%);
  color: #a25eb5;
}

.ZoneImage img {
  margin-top: auto;
}

.ZoneImage .name {
  margin-top: auto;
  color: #bd8bca;
  font-weight: 700;
}

.ZoneImage.mobile {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
  min-height: 35px;
}

.ZoneImage.mobile .mobile-row {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.ZoneImage.mobile .mobile-row .mobile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.ZoneImage.mobile img {
  margin-top: unset;
  width: auto;
  max-height: 67%;
}

.ZoneImage.mobile img[alt="Europe"] {
  width: auto;
  max-height: 40%;
}

.ZoneImage.mobile img[alt="Oceania"] {
  width: auto;
  max-height: 50%;
}

.ZoneImage.mobile .mobile-row .mobile-name {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 15px;
}

.ZoneImage.mobile .name {
  margin-top: unset;
  display: inline-block;
  color: #2F3D4D;
  font-weight: 600;
}

.ZoneImage.mobile .name {
  font-weight: 700;
}

.ZoneImage.mobile .mobile-row .mobile-icon {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 18px;
  color: #dab9e1;
}
