.Location {
  color: #bd8bca;
  font-weight: 800;
}

.Location:hover {
  cursor: pointer;
  color: #a25eb5;
}

.Location.mobile-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  color: #2F3D4D;
  font-weight: normal;
}

.Location.mobile-row:hover {
  cursor: pointer;
  color: #a25eb5;
}

.Location.mobile-row .mobile-name {
  display: flex;
  align-items: center;
  justify-content: left;
}

.Location.mobile-row .mobile-icon {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: right;
  font-size: 18px;
  color: #dab9e1;
}
