.Button .emphasis {
  background-color: #BC8DC8;
  color: #ffffff;
  border: 3px solid #BC8DC8;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  padding: 12px 0px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 22px;
}

.Button .ghost {
  background-color: #ffffff;
  color: #BC8DC8;
  border: 3px solid #BC8DC8;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  padding: 12px 0px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 22px;
}

.Button .emphasis:hover {
  cursor: pointer;
  background-color: #A161B3;
  border: 3px solid #A161B3;
  box-shadow: 0px 5px 10px -5px #888888;
}

.Button .ghost:hover {
  cursor: pointer;
  border: 3px solid #A161B3;
  color: #A161B3;
  box-shadow: 0px 5px 10px -5px #888888;
}

.Button .emphasis:active {
  cursor: pointer;
  background-color: #A161B3;
  border: 3px solid #A161B3;
}

.Button .ghost:active {
  cursor: pointer;
  border: 3px solid #A161B3;
  color: #A161B3;
}

.Button .emphasis:disabled {
  background-color: #EBEBEC;
  border: 3px solid #EBEBEC;
  cursor: not-allowed;
}

.Button .ghost:disabled {
  border: 3px solid #EBEBEC;
  color: #EBEBEC;
  cursor: not-allowed;
}

.Button .emphasis:disabled:hover,
.Button .ghost:disabled:hover {
  box-shadow: none;
}

.Button .emphasis:focus,
.Button .ghost:focus {
  outline:0;
}

@media (max-width: 376px) {
  .Button {
    display: flex;
    align-items: center;
  }

  .Button .emphasis,
  .Button .ghost {
    width: 85%;
    margin: 0 auto 10px auto;
  }
}
