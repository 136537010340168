.Regions {
  margin-top: 35px;
  /* text-align: center; */
  border-top: 2px solid #bd8bca;
}

.Regions .title {
  margin: 25px 0px 20px 0px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.Regions .regions-list {
  width: 95%;
  margin: 35px auto 0px auto;
}

.Regions .regions-list-seven-or-more {
  display: block;
}

.Regions .regions-list-seven-or-more > div {
  display: inline-block;
  margin-bottom: 15px;
  width: calc(100% / 7);
}

.Regions .regions-list-less-than-seven {
  display: flex;
  justify-content: space-between;
}

.Regions .regions-list-less-than-four {
  width: 55%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.Regions .regions-list-less-than-two {
  width: 25%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.Regions.mobile {
  margin-top: unset;
  border: none;
}

.Regions.mobile.selected {
  display: none;
}

.Regions.mobile .title {
  margin: 15px 0px 20px 0px !important;
  font-size: 20px !important;
}

.Regions.mobile .regions-list {
  width: 100%;
  margin: 0px auto;
}

.Regions.mobile .regions-list .backToZones {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.Regions.mobile .regions-list .backToZones:hover {
  cursor: pointer;
}

.Regions.mobile .regions-list .backToZones .icon {
  color: #bd8bca;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 5px;
}

.Regions.mobile .regions-list .backToZones .zoneName {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
  font-weight: 600;
}

.Regions.mobile .regions-list .mobile-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.Regions.mobile .regions-list .mobile-row:hover {
  cursor: pointer;
  color: #a25eb5;
}

.Regions.mobile .regions-list .mobile-row .mobile-name {
  display: flex;
  align-items: center;
  justify-content: left;
}

.Regions.mobile .regions-list .mobile-row .mobile-icon {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: right;
  font-size: 18px;
  color: #dab9e1;
}

@media (max-width: 768px) {
  .Regions .title.region-selected {
    display: none;
  }
}
