.Languages {
  margin-top: 35px;
  text-align: center;
  border-top: 2px solid #bd8bca;
}

.Languages .title {
  margin: 25px 0px 20px 0px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.Languages .languages-list {
  width: 95%;
  margin: 35px auto 0px auto;
}

.Languages .language-list-seven-or-more {
  display: block;
}

.Languages .language-list-seven-or-more span {
  display: inline-block;
  margin-bottom: 15px;
  width: calc(100% / 7);
}

.Languages .language-list-less-than-seven {
  display: flex;
  justify-content: space-between;
}

.Languages .language-list-less-than-four {
  width: 35%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.Languages .language-list-less-than-two {
  width: 25%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.Languages .language-list-one {
  width: 25%;
  margin: 0 auto;
  display: block;
  text-align: center;
}


.Languages.mobile {
  margin-top: unset;
  border: none;
}

.Languages.mobile.selected {
  display: none;
}

.Languages.mobile .title {
  margin: 15px 0px 20px 0px !important;
  font-size: 20px !important;
}

.Languages.mobile .languages-list {
  width: 100%;
  margin: 0px auto;
}

.Languages.mobile .languages-list .backToRegions {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.Languages.mobile .languages-list .backToRegions:hover {
  cursor: pointer;
}

.Languages.mobile .languages-list .backToRegions .icon {
  color: #bd8bca;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 5px;
}

.Languages.mobile .languages-list .backToRegions .regionName {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
  font-weight: 600;
}
