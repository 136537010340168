.SelectYourRegion .body {
  width: 900px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.SelectYourRegion .title {
  margin: 15px 0px 20px 0px;
  font-size: 20px;
  font-weight: bold;
}

.SelectYourRegion .zones {
  display: flex;
  justify-content: space-between;
}

.SelectYourRegion .zones-mobile,
.SelectYourRegion .regions-mobile {
  display: none;
}

.SelectYourRegion .zones-mobile.selected {
  display: none;
}

@media (max-width: 1100px) {
  .SelectYourRegion .body {
    width: 800px;
  }
}

@media (max-width: 991px) {
  .SelectYourRegion .body {
    width: 750px;
  }
}

@media (max-width: 768px) {
  .SelectYourRegion .title.zone-selected {
    display: none;
  }

  .SelectYourRegion .body {
    width: 300px;
  }

  .SelectYourRegion .backButton {
    display: none;
  }

  .SelectYourRegion .zones,
  .SelectYourRegion .regions {
    display: none;
  }

  .SelectYourRegion .zones-mobile,
  .SelectYourRegion .regions-mobile {
    display: block;
  }
}

@media (max-width: 576px) {
  .SelectYourRegion .body {
    width: 300px;
  }

  .SelectYourRegion .backButton {
    display: none;
  }
}

@media (max-width: 376px) {
  .SelectYourRegion .body {
    width: 275px;
  }
}
