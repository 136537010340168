.ForgotPasswordError .backButton {
  position: relative;
  width: 85%;
  margin: 0 auto;
  max-width: 766px;
}

.ForgotPasswordError .body {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.ForgotPasswordError img {
  width: 50px;
}

.ForgotPasswordError .message {
  margin: 15px 0px 20px 0px;
  font-size: 18px;
  font-weight: 800;
}

.ForgotPasswordError .details {
  margin-bottom: 20px;
}

.ForgotPasswordError .details .loginID {
  margin-left: 3px;
  font-weight: bold;
}

.ForgotPasswordError .contact {
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  .ForgotPasswordError .backButton {
    width: 90%;
  }
}

@media (max-width: 376px) {
  .ForgotPasswordError .body {
    width: 275px;
  }
}
