.ResetPasswordSuccess {
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.ResetPasswordSuccess img {
  width: 50px;
}

.ResetPasswordSuccess .success {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}

.ResetPasswordSuccess .redirect {
  margin-top: 25px;
  font-size: 14px;
}

@media (max-width: 376px) {
  .ResetPasswordSuccess {
    width: 275px;
  }
}
