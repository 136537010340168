.Region {
  color: #bd8bca;
  font-weight: 700;
  /* text-align: center; */
}

.Region:hover {
  cursor: pointer;
  color: #a25eb5;
}
