.FooterCopyright {
  color: #5A6673;
}

.FooterCopyright .copyright-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterCopyright .doterra-o {
  width: .85em;
  filter: invert(44%) sepia(16%) saturate(467%) hue-rotate(171deg) brightness(83%) contrast(85%);
}

.FooterCopyright .copyright-text {
  margin-left: 8px;
}
