.SignIn .backButton {
  position: relative;
  width: 85%;
  margin: 0 auto;
  max-width: 766px;
}

.signInBannerImage {
  margin-top: -45px;
}

.signInBannerImage .backButton {
  position: relative;
}

.signInBannerImage .top-region-selector {
  position: relative;
}

.signInBackButton .BackButton {
  top: 0px;
  left: 65px;
  bottom: 0px;
}
.top-region-selector .RegLangSelector {
  top: 0px;
  left: 1400px;
  bottom: 0px;
}

.SignIn .body {
  width: 330px;
  margin: 0 auto;
}

.SignIn .title {
  font-size: 20px;
  font-weight: 700;
  color: #2F3D4D;
  text-align: center;
  margin-bottom: 25px;
  padding: 0px 5px;
}

.SignIn .subtitle {
  font-size: 14px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: -15px;
}

.SignIn .errors {
  font-size: 12px;
  font-weight: 700;
  color: #E16C6A;
  position: relative;
  margin-bottom: 15px;
}

.SignIn .errors .error-icon {
  width: 14px;
  position: absolute;
  top: 0;
  left: 5px;
}

.SignIn .errors .error-text {
  display: block;
  margin-left: 25px;
}

.login-error-line2 {
  margin-top: 15px;
}

.login-error-line2 a, .login-error-line2 a:hover{
  text-decoration: underline;
}
.SignIn .label {
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 8px;
}

.SignIn .form-field {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-size: 17px;
  margin-bottom: 15px;
  padding: 8px 0px 8px 5px;
  border: none;
  border-bottom: 1px solid #CBCBCB;
  border-radius: unset;
}

.SignIn .form-field::placeholder {
  font-size: 17px;
  color: #CECECE;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  margin-top: 2px;
}

.SignIn .form-field:not([value=""]) {
  color: #364553;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  border-bottom: 1px solid #CBCBCB;
  padding: 8px 5px;
  width: 98.5%;
}

.SignIn .form-field-text:not([value=""]),
.SignIn .form-field-password:not([value=""]) {
  font-size: 17px;
}

.SignIn .form-field:focus {
  outline: none;
}

.SignIn .has-error {
  background-color: #FDF3F4;
  border-bottom: 1px solid #D96161;
}

.SignIn .has-error::placeholder {
  color: #D96161;
}

.SignIn #password label {
  position: relative;
}

.SignIn #password label .showHidePassword {
  text-align: center;
}

.SignIn #password label .showHidePasswordText {
  display: block;
  font-size: 10px;
  text-align: center;
  font-weight: 200;
}

.SignIn .showHidePassword {
  color: #747D9F;
  position: absolute;
  right: 0;
  bottom: -40px;
}

.SignIn .showHidePassword:hover {
  cursor: pointer;
}

.SignIn .sign-in-options {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  margin: 5px 0px 25px 0px;
}

.SignIn .remember {
  position: relative;
}

.SignIn .remember .label {
  display: inline-block;
  margin-left: 3em;
}

.SignIn .remember .form-field-checkbox {
  position: absolute;
  left: -4px;
  top: -12px;
}

.SignIn input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.SignIn input[type=checkbox]::-ms-check {
  display: none;
}

.SignIn input[type=checkbox] {
  background-image: url("./unchecked.svg");
  background-position: 47% 40%;
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  border: none;

}

.SignIn input[type=checkbox]:focus {
  outline: 0;
  -webkit-appearance: none !important;
}

.SignIn input[type=checkbox]:hover {
  cursor: pointer;
}

.SignIn input[type=checkbox]:checked {
  background-image: url("./checked.svg");
  background-position: 40% 40%;
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  border: none;
}

.SignIn .form-field-checkbox:checked,
.SignIn .form-field-checkbox:not(:checked) {
  font-size: 1.2em;
  width: 2em;
  background-color: white;
}

.SignIn .forgot {
  text-decoration: underline;
  margin-left: auto;
  text-align: right;
  color: #B479C3;
}

.loginErrorModalButton {
  background: none;
  outline:0;
  border: 0;
  color: #E16C6A;
  text-decoration: underline;
  font-weight: 700;
  padding: 0;
}

.SignIn .forgot:hover {
  color: #A161B3;
}

@media (max-width: 1100px) {
  .signInBackButton .BackButton {
    left: 35px;
  }
}

@media (max-width: 576px) {
  .SignIn .backButton {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .signInBackButton .BackButton {
    left: 15px;
  }
}

@media (max-width: 376px) {
  .SignIn .body {
    width: 275px;
  }
}
