.ForgotPasswordSuccess {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.ForgotPasswordSuccess img {
  width: 50px;
}

.ForgotPasswordSuccess .message {
  margin: 15px 0px 20px 0px;
  font-size: 18px;
  font-weight: bold;
}

.ForgotPasswordSuccess .beforeEmail {
  margin-bottom: 5px;
}

.ForgotPasswordSuccess .email {
  font-weight: bold;
}

.ForgotPasswordSuccess .afterEmail {
  margin-top: 5px;
  margin-bottom: 25px;
}

@media (max-width: 376px) {
  .ForgotPasswordSuccess {
    width: 275px;
  }
}
