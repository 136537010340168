.login-error-overlay {
    position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    z-index: 1040;
    opacity: 0.7;
    background-color: #EBEBEB;
  }
  .login-error-modal-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    outline: 0;
    transition: opacity 0.15s linear;
    overflow: scroll;
  }
  .login-error-modal{
    position: relative;
    top: 5em;
    width: 700px;
  }
  @media (min-width: 544px) {
        .login-error-modal {
            width: 544px;
            margin: 30px auto;
        }
        .modal-content {
            -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
    }
    @media (max-width: 544px) {
        .login-error-modal {
            width: 360px;
            margin: 0 auto;
            top: 1em;
        }
        .modal-body {
            padding: 0 15px;
        }
        .actions .Button .emphasis, .actions .Button .ghost {
            margin: 0;
            margin-left: 6px;
        }        
    }
  .modal-content {
    padding-bottom: 20px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.18);
    border: none;
    border-top: 6px solid transparent;
    border-color: #BD8BCA;
    border-radius: 5px;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
  }
  .modal-header {
    border: none;
    padding-bottom: 0;
    padding-left: 30px;
    background-color: #FFFFFF;
    height: 34px;
    padding: 15px;
    background: #F5EEF7;
  }
  .modal-title {
    color: #A25EB5;
    font-size: 22px;
    margin: 0;
    line-height: 1.42857143;
  }
  @media (min-width: 768px) {
  .modal-body {
    padding: 10px 25px 0;
  }
}
.actions {
    margin-top: 16px;
}
.actions .Button {
    display: flex;
    justify-content: flex-end;
}
.actions .Button .emphasis, .actions .Button .ghost {
    width: auto;
    padding: 12px 16px;
    margin-left: 6px;
}
.modalBodyLink {
  text-decoration: underline !important;
  font-weight: bold !important;
  color: #A161B3 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}
table, td {
  border: 1px solid #2F3D4D;
  padding: 5px;
}