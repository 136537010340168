.FooterLinks {
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid #E8E8E8;
  font-size: 12px;
  padding: 24px 0px;
  color: #5A6673;
  /* max-width: 766px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
}

.FooterLinks .links {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}

.FooterLinks .links div {
  display: inline-block;
  text-align: center;
  width: 50%;
  /* margin: 0 35px; */
  /* width: 33.333%; */
}

.FooterLinks .links div:first-child {
  text-align: left;
}

.FooterLinks .links div:last-child {
  text-align: right;
}

.FooterLinks .teconsent-toggle-display:hover {
  cursor: pointer;
}

.FooterLinks .teconsent-toggle-display {
  display: none;
}

@media (max-width: 576px) {
  .FooterLinks {
    width: 90%;
    font-size: 12px;
  }

  .FooterLinks .links div {
    display: inline-block;
    /* margin: 0 15px; */
  }
}

@media (max-width: 376px) {
  .FooterLinks {
    /* font-size: 10px; */
  }

  .FooterLinks .links {
    width: 275px;
  }

  .FooterLinks .links div {
    display: inline-block;
    /* margin: 0 8px; */
  }
}
